.text {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.logo {
  grid-row: 1;
  grid-column: 1;
  padding: 5px;
  margin-right: 1rem;
  border-radius: 7px;
  top: 20px;
  width: 20vw;
  left: 20px;
}

.ueberschrift {
  grid-row: 1;
  grid-column: 2;
  color: yellow;
  font-size: 10vh;
}

.kopf {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  grid-auto-columns: 1fr auto 1fr;
  grid-auto-rows: auto 1fr;
  background: black;
  color: white;
}

.canvas {
  align-self: stretch;
  justify-self: stretch;
  grid-row: 2;
  grid-column: 1 / span 3;
}
