
h1 {
  text-align: center
}

@media (max-width: 960px) {
  .textfield {
    width: 100%;
  }
}

@media (min-width: 960px) {
  .textfield {
    width: 20%;
  }
}
.positionierung {
  display: flex;
  align-items: center;
}

@media (min-width: 960px) {
  .ausrichtung {
    flex-direction: column;
  }
}

@media (max-width: 960px) {
  .ausrichtung {
    flex-direction: row;
  }
}

.bezeichnung {
  font-size: 20px;
  font-weight: bold;
}
