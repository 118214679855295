@media (max-width: 960px){
  .root {
    //position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-grow: 1;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr auto;
  }
}

  @media (min-width: 960px) {
    .root{
      text-align: center;
      justify-content: center;
      grid-template-rows: auto 1fr auto;
    }
  }

  .appBar {
    grid-row: 1;
    grid-column: 1;
    text-align: center;
  }

  .inhalt {
    grid-row: 2;
    grid-column: 1;
    overflow-y: scroll;
    //-webkit-overflow-scrolling: touch;
  }

  .logo {
    width: 120px;
    background-color: white;
    padding: 5px;
    margin-right: 1rem;
    border-radius: 7px;
  }

  .ueberschrift {
    width: 100%;
    font-weight: bold !important;
  }
@media (max-width: 960px) {
  .buttonContainer {
    display: flex;
    position: sticky;
    bottom: 0 !important;
    overflow: hidden !important;
    justify-content: center;
    background: #ffffff;
    height: 40px;
    padding: 10px;
    box-shadow: 0 -3px 15px black;
    grid-row: 3;
    grid-column: 1;
  }
}
@media (min-width: 960px) {
  .buttonContainer {
    display: flex;
    position: sticky;
    bottom: 0 !important;
    overflow: hidden !important;
    justify-content: center;
    background: #ffffff;
    height: 40px;
    padding: 10px;
    grid-row: 3;
    grid-column: 1;
  }
}
@media (max-width: 960px) {
  button {
    width: 100%;
  }
}
